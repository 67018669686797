(function () {
	'use strict';

	angular
		.module('common')
		.factory('manageTheme', [
			'firebaseIO',
			'seedcodeCalendar',
			'utilities',
			manageTheme,
		]);
	function manageTheme(firebaseIO, seedcodeCalendar, utilities) {
		var styleNode;
		var styleID;
		var selectedStyles;
		var styleBackup;
		var enabledTheme;

		return {
			addDocumentStyles: addDocumentStyles,
			getThemeStyles: getThemeStyles,
			setThemeStyles: setThemeStyles,
			getThemeStylesBackup: getThemeStylesBackup,
			saveTheme: saveTheme,
			getSavedTheme: getSavedTheme,
			applyThemeSetting: applyThemeSetting,
			removeAllCustomStyles: removeAllCustomStyles,
			setBodyTheme: setBodyTheme,
		};

		function addDocumentStyles(cssString) {
			styleID = 'dbk-custom-styles';
			if (!styleNode) {
				styleNode = document.createElement('style');
				styleNode.setAttribute('id', styleID);
				document.body.appendChild(styleNode);
			}
			styleNode.innerHTML = cssString;
		}
		function getThemeStylesBackup() {
			return styleBackup;
		}

		function getThemeStyles() {
			return selectedStyles;
		}

		function setThemeStyles(styles) {
			selectedStyles = styles;
			addDocumentStyles(styles);
			return selectedStyles;
		}

		function saveTheme(styles, callback) {
			setThemeStyles(styles);
			styleBackup = styles;
			firebaseIO.setGroupData('', 'theme', styles, false, onSetTheme);
			function onSetTheme(result) {
				if (callback) {
					callback(result);
				}
			}
		}

		function getSavedTheme(callback) {
			firebaseIO.getGroupData('theme', onGetTheme);
			function onGetTheme(result) {
				setThemeStyles(result);
				styleBackup = result;
				if (callback) {
					callback(result);
				}
			}
		}

		function applyThemeSetting(theme, fromUserSelection) {
			var selectedID;

			var availableThemes = [
				{
					id: 'dark',
					path: 'css/themes/dark.css',
				},
			];

			var config = seedcodeCalendar.get('config');
			if (
				(window.matchMedia &&
					window.matchMedia('(prefers-color-scheme: dark)').matches &&
					theme === 'darkModeAuto') ||
				theme === 'darkMode'
			) {
				// dark mode
				config.darkMode = true;
				selectedID = 'dark';
			} else {
				config.darkMode = false;
				selectedID = null;
			}

			// Set theme css
			for (var i = 0; i < availableThemes.length; i++) {
				if (availableThemes[i].id === selectedID) {
					loadStylesheet(availableThemes[i].path, null, false);
				} else {
					loadStylesheet(availableThemes[i].path, null, true);
				}
			}

			if (fromUserSelection) {
				setBodyTheme();
			}
		}

		function loadStylesheet(file, headElement, disabled) {
			var fileID = utilities.stringToID(file);
			var fileExists = document.getElementById(fileID);

			if (fileExists) {
				fileExists.disabled = disabled;
				return;
			} else if (disabled) {
				// If we want to disable the stylesheet but it isn't loaded then there is nothing to do here
				return;
			}

			var head = headElement
				? headElement
				: document.getElementsByTagName('head')[0];
			var link = document.createElement('link');
			link.rel = 'stylesheet';
			link.type = 'text/css';
			link.href = file + '?v={build-data::version}';
			link.id = fileID;
			link.media = 'all';
			link.disabled = disabled;
			head.appendChild(link);

			enabledTheme = fileID;
		}

		function removeElement(id) {
			if (!id) {
				return;
			}
			var element = document.getElementById(id);
			if (element) {
				element.disabled = true;
				element.parentNode.removeChild(element);
			}
		}

		function removeAllCustomStyles(init) {
			if (!init) {
				// Remove custom user styles
				removeElement(styleID);

				// Remove selected themes
				removeElement(enabledTheme);

				// Clear stored theme values
				styleID = null;
				styleNode = null;
				selectedStyles = null;
				styleBackup = null;
				enabledTheme = null;
			}
		}

		function setBodyTheme() {
			const config = seedcodeCalendar.get('config');

			if (!config) {
				return;
			}

			const classList = document.body.classList;
			const removeClass = config.darkMode ? 'light' : 'dark';
			const addClass = config.darkMode ? 'dark' : 'light';

			if (classList.contains(removeClass)) {
				classList.remove(removeClass);
			}
			classList.add(addClass);
		}
	}
})();
